/**
 *
 * PATIENT Index Page
 *
 */

import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Layout from '../components/dfa-theme/layout'
import myEmitter from '../lib/emitter'
import imgHeadline from '../images/hero-headline.png'
import imgPain from '../images/painscale-img.png'
import imgUnder from '../images/under-1-hr-img.png'
import { withPrefix } from 'gatsby-link'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import PageIndicator from '../components/page-indicator'
import { pushEventGA }  from '../lib/util.helper'

let el_top = 0
let el_bottom = 0
let el = null
let elHeight = 0
let elOffset = 0
let winHeight = 0
let elHome = ""
let elHeader = ""
let ACTIVE_MENU_ON_SCROLL = null; //onscroll

const scrollSections = [
  'home',
  'what-is-zipsor',
  'why-an-nsaid',
  'why-zipsor',
  'zipsor-copay',
]

class IndexPage extends React.Component{

  state = {
    activesection: "home"
  }

  throttled = (delay, fn) => {
    let lastCall = 0
    return function(...args) {
      const now = new Date().getTime()
      if (now - lastCall < delay) {
        return
      }
      lastCall = now
      return fn(...args)
    }
  }

  debounced = (delay, fn) => {
    let timerId;
    return function (...args) {
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(() => {
        fn(...args);
        timerId = null;
      }, delay);
    }
  }

  handleScroll = () => {
    scrollSections.map(item => {
      el = document.getElementById(item)
      elHeight = el.clientHeight;
      elOffset = elHeight * 0.5;
      el_top = el.getBoundingClientRect().top
      el_bottom = el.getBoundingClientRect().bottom-elOffset
      if(el_top<=elHeight && el_bottom<=winHeight)
      {
         myEmitter.emit('UPDATE_ACTIVE_SECTION_ONSCROLL', item)
      }
    })
  }

  // trackScroll = () => {
  //   scrollSections.map(item => {
  //     el = document.getElementById(item)
  //     elHeight = el.clientHeight;
  //     elOffset = elHeight * 0.5;
  //     el_top = el.getBoundingClientRect().top
  //     el_bottom = el.getBoundingClientRect().bottom-elOffset
  //     if(el_top<=elHeight && el_bottom<=winHeight)
  //     {
  //       if(el_bottom>=0)
  //       {
  //         pushEventGA(item,"scroll",item + ' section')
  //       }
  //     }
  //   })
  // }

  scrollListener = () => {
    this.throttled(500, this.handleScroll())
      if(elHeader.clientHeight === elHome.getBoundingClientRect().top) {
        myEmitter.emit('UPDATE_ACTIVE_SECTION_ONSCROLL', "home")
      }
  }

  componentDidMount() {
    winHeight = document.documentElement.clientHeight;
    elHome = document.getElementById("home")
    elHeader = document.getElementById("base-header")
    window.addEventListener('scroll',this.scrollListener);
   // window.addEventListener('scroll',this.debounced(2000, this.trackScroll))
  }

  componentWillMount() {
    ACTIVE_MENU_ON_SCROLL = myEmitter.addListener(`UPDATE_ACTIVE_SECTION_ONSCROLL`, (data) => {
      this.setState({
        activesection: data
      })
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollListener);
   // window.removeEventListener('scroll',this.debounced(2000, this.trackScroll))
    ACTIVE_MENU_ON_SCROLL && ACTIVE_MENU_ON_SCROLL.remove();
  }

  render(){
    return(
      <Layout className="index-page">
        <Row className="hero-row" id="home">
          <Row className="inner-container" style={{margin:'auto'}}>
            <Col xs={12} sm={6}>
            </Col>
            <Col xs={12} sm={6}>
              <div className="hero-txt-content">
                <img src={imgHeadline} style={{width: '100%'}} alt="headline"/>
                <a href={withPrefix('/pdf/saving-card.pdf')} target="_blank" rel="noopener noreferrer" className="btn-card" onClick={() => { pushEventGA('button','click','GET SAVINGS CARD') }}>GET SAVINGS CARD</a>
                <p className="text-white">ZIPSOR<sup>&reg;</sup> (diclofenac potassium) is a prescription Non-Steroidal Anti-Inflammatory Drug (NSAID). ZIPSOR is used for relief of mild-to-moderate pain in adults (18 years of age and older).</p>
              </div>
            </Col>
          </Row>
        </Row>
          <Row id="what-is-zipsor">
            <Row className="content-block">
              <Col xs={12}>
                <p className="content-big-head text-blue">What is ZIPSOR?</p>
                <p>ZIPSOR is a prescription treatment for mild-to-moderate acute pain in adults 18 years of age or older.</p>
                <p>The most common side effects of ZIPSOR include abdominal pain, constipation, diarrhea, dyspepsia, nausea, and vomiting; also reported were dizziness, headache, somnolence, pruritus, and increased sweating.</p>
                <p className="content-small-head">Why is ZIPSOR prescribed?</p>
                <p>ZIPSOR is prescribed for different types of pain, including after surgery or for an injury such as a sprain or strain.</p>
                <p  className="content-small-head">How do I know if ZIPSOR is right for me?</p>
                <ul>
                  <li>Your pain is acute—most acute pain lasts less than 6 months and goes away when the underlying problem has healed</li>
                  <li>You prefer not to take an opioid</li>
                  <li>Your pain is mild to moderate—which means that you would probably rate it no higher than a 7 on a scale of 0 to 10</li>
                </ul>
                <div style={{textAlign: "center"}}><img src={imgPain} className="content-block-img" alt="pain"/></div>
              </Col>
            </Row>
          </Row>
        <Row className="nsaid-row" id="why-an-nsaid">
          <Row className="content-block">
            <Col xs={12}>
              <p className="content-big-head">Why an NSAID?</p>
              <p>The US government’s Centers for Disease Control and Prevention (CDC) recommends that<sup>1</sup>:</p>
              <ul>
                <li>Acute mild-to-moderate pain may be managed without opioids</li>
                <li>Non-opioid options include NSAIDs</li>
                <li><strong>You should ask your doctor about ways to relieve your pain that do not involve prescription opioids</strong></li>
              </ul>
              <p>NSAIDs have been used for pain for nearly 60 years and are widely prescribed and recommended by physicians.</p>
            </Col>
          </Row>
        </Row>
        <Row id="why-zipsor">
          <Row className="content-block">
            <Col xs={12}>
              <p className="content-big-head text-blue">Why ZIPSOR?</p>
              <p>ZIPSOR is designed for rapid and consistent absorption in your body with technology called ProSorb<sup>&reg;</sup>.<sup>2</sup></p>
              <p><strong>The technology helps the medicine quickly distribute in your stomach, so it can be rapidly absorbed in your body.<sup>3</sup></strong></p>
              <p>ZIPSOR was studied in a clinical trial comparing ZIPSOR to placebo for patients who underwent bunionectomy surgery, which is the removal of a bony deformity on the foot.<sup>4</sup></p>
              <div style={{textAlign: "center"}}><img src={imgUnder} className="content-block-img" alt="under"/></div>
            </Col>
          </Row>
        </Row>
        <Row className="copay-row" id="zipsor-copay">
          <Row className="content-block">
            <Col xs={12}>
              <p className="content-big-head">Download a copay card to save on ZIPSOR</p>
              <p>You could save money on ZIPSOR by downloading a copay card.*</p>
              <p>*Terms and conditions may apply. View savings card for full terms and conditions.</p>
              <div style={{textAlign: 'center'}}><a href={withPrefix('/pdf/copay-card.pdf')} target="_blank" rel="noopener noreferrer" className="btn-card" onClick={() => { pushEventGA('button','click','DOWNLOAD COPAY CARD') }}>DOWNLOAD COPAY CARD</a></div>
              <p><strong>Please see <AnchorLink href="#isi" className="custom-isi-orangelink" onClick={() => { pushEventGA('link','click','EXPAND FULL ISI') }}>Important Safety Information</AnchorLink> and full <a href={withPrefix('/pdf/prescribing-information.pdf')} target="_blank" rel="noopener noreferrer" className="custom-isi-orangelink" onClick={() => { pushEventGA('link','click','DOWNLOAD PRESCRIBING INFORMATION') }}>Prescribing Information</a>, including BOXED WARNING.</strong></p>
            </Col>
          </Row>
        </Row>
        <PageIndicator theme={["home","why-an-nsaid","zipsor-copay"].includes(this.state.activesection) ? "light" : "dark" }/>
      </Layout>
    )
  }
}




export default IndexPage

/**
 *
 * Patient indicator component
 *
 */

import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'

const Indicator = props => (
    <AnchorLink
    href={props.href}
    className={`indicator ${props.status} ${props.className}`}
    >
        <div className="index-nav-indicator"></div>
        <div className="index-nav-text">{props.caption}</div>
    </AnchorLink>
)

export default Indicator